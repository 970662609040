import { Injectable } from '@angular/core';
import { Http } from '@angular/http';

import { DataService } from '@app/services/data.service';

@Injectable({
	providedIn: 'root'
})
export class UserService extends DataService {

	constructor(http: Http) { 
		super('?type=users', http)
	}
	
}