import { Injectable } from '@angular/core';
import { Http } from '@angular/http';

import { DataService } from '@app/services/data.service';

@Injectable({
	providedIn: 'root'
})
export class TaskService extends DataService {

	constructor(private http: Http) { 
		super('?type=tasks', http);
	}

	add(userId: number, taskId: number) {
		return this.http.get(this.apiUrl + '&action=add&userId=' + userId + '&taskId=' + taskId);
	}
	
}