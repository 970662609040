import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpModule } from '@angular/http';

import { AppComponent } from './app.component';

// routes
import { AppRoutingModule } from './routing.module';

// services
import { DataService } from '@app/services/data.service';
import { UserService } from '@app/services/user.service';
import { TaskService } from '@app/services/task.service';

// components
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { UserComponent } from './pages/user/user.component';

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    UserComponent
  ],
  imports: [
    BrowserModule,
    HttpModule,
    AppRoutingModule
  ],
  providers: [
  	DataService,
    UserService,
  	TaskService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
