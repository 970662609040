import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { TaskService } from '@app/services/task.service';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html'
})
export class UserComponent implements OnInit {

	userId: number = 0;
	tasks: any[];
  constructor(
  	private taskService: TaskService,
  	private router: Router,
  	private route: ActivatedRoute) { }

  ngOnInit() {
  	this.taskService.getAll()
  		.subscribe(data => {
  			this.tasks = data.json()
  		});

  	this.route.paramMap
		.subscribe(params => {
			this.userId = +params.get('id');
		});
  }

  postTask(taskId: number, taskValue: any) {
    let audio = new Audio('/assets/audio/positive.mp3');
    if(taskValue === '-0,10')
      audio = new Audio('/assets/audio/negative.mp3');
    audio.play();

    this.taskService.add(this.userId, taskId)
      .subscribe(data => {
        if(data.status === 200) {
  				this.router.navigate(['/'])
  			}
  		})
  }

}
