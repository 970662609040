import { Component, OnInit } from '@angular/core';

import { UserService } from '@app/services/user.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html'
})
export class DashboardComponent implements OnInit {

	users: any[];

  constructor(private userService: UserService) { }

  ngOnInit() {
  	this.userService.getAll()
  		.subscribe(data => {
  			this.users = data.json()
  		});
  }

  showPoints(money: any) {
    let credits = money.replace(/,/g,'');
    return credits / 10;
  }

  playSound() {
    let audio = new Audio('/assets/audio/start.mp3');
    audio.play();
  }

}
