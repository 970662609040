import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { DashboardComponent } from '@app/pages/dashboard/dashboard.component';
import { UserComponent } from '@app/pages/user/user.component';
// import { CustomerComponent } from '@app/pages/customer/customer.component';
// import { CostsComponent } from '@app/pages/costs/costs.component';
// import { CostComponent } from '@app/pages/cost/cost.component';

const appRoutes: Routes = [
	{
		path: '',
		component: DashboardComponent
	},
	{
		path: 'user/:id',
		component: UserComponent
	},
	{
		path: '**',
		component: DashboardComponent
	}
];

@NgModule({
	imports: [
		RouterModule.forRoot(appRoutes)
	],
	exports: [
		RouterModule
	]
})

export class AppRoutingModule {

};