import { Injectable } from '@angular/core';
import { Http } from '@angular/http';

@Injectable({
	providedIn: 'root'
})
export class DataService {

	apiUrl = 'http://ileri-bank.codio.nl/api/';

	constructor(
		url: string, 
		private _http: Http
	) { 
		this.apiUrl += url;
	}

	getAll() {
		return this._http.get(this.apiUrl);
	}

	getById(id: number) {
		return this._http.get(this.apiUrl + '&id=' + id);
	}

	create() {
		//
	}

	update() {
		//
	}

	delete(id) {
		//
	}
}
